import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CpVehicleViewModel, CustomerJobInputModel, FleetAuditAvailabilityInputModel, FleetAuditViewModel } from '../../shared/tams-api';
import { Observable } from 'rxjs';

const url = environment.api;

@Injectable({
  providedIn: 'root'
})
export class BookingService {
  constructor(private http: HttpClient) {}

  getVehiclesByRegistration(registrationForm: { registration: string; trailerNumber: string }): Observable<CpVehicleViewModel> {
    let params = new HttpParams().set('registration', registrationForm.registration);
    if (registrationForm.trailerNumber) {
      params = params.set('trailerNumber', registrationForm.trailerNumber);
    }
    return this.http.get<CpVehicleViewModel>(`${url}DriverPortal/vehicles`, { params });
  }

  addNewJob(jobDetails: CustomerJobInputModel) {
    return this.http.post(url + 'DriverPortal/job/add', jobDetails);
  }

  getFleetAuditDetails(fleetAuditGuid: string) {
    return this.http.get<FleetAuditViewModel>(url + `DriverPortal/fleet-audit/${fleetAuditGuid}`);
  }

  confirmFleetAuditBooking(availabilityDetails: FleetAuditAvailabilityInputModel) {
    return this.http.post(url + 'DriverPortal/fleet-audit/confirmBooking', availabilityDetails);
  }
}
